<template>
  <div v-if="!loading" id="exampleImageCarousel" :class="styleClass">
    <div class="carousel-inner">
      <div v-for="(image, index) in images" :key="index" class="carousel-item" :class="[ index === 0 ? 'active' : '' ]">
        <div class="row">
          <div v-for="(i, idx) in image" :key="idx" class="col">
            <a
            class="col-sm-3 col-3 configurator__carousel-i"
            data-bs-toggle="modal"
            href="#exampleImageModal"><img
              :src="$filters.image(i.path)"
              class="configurator__carousel-img"
              :data-image-id="i.path"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev bg-transparent"
      href="#exampleImageCarousel"
      role="button"
      data-bs-slide="prev"
    >
      <i class="fas fa-chevron-left"></i>
    </a>
    <a
      class="carousel-control-next bg-transparent"
      href="#exampleImageCarousel"
      role="button"
      data-bs-slide="next"
    >
      <i class="fas fa-chevron-right"></i>
    </a>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
import _ from 'lodash'
export default {
  name: 'Carousel',
  props: {
    styleClass: {
      type: String
    }
  },
  setup () {
    const store = useStore()
    const images = computed(() => {
      const imgs = store.getters['configurator/exampleImages']
      const result = _.orderBy(imgs, ['sort'], ['asc'])
      const limit = 4
      const arr = []
      for (let i = 0; i < result.length; i += limit) {
        arr.push(result.slice(i, i + limit))
      }
      return arr
    })
    const loading = computed(() => {
      return store.getters['configurator/exampleImagesLoading']
    })
    onMounted(() => {
      document.querySelector('#exampleImageCarousel').addEventListener('click', (e) => {
        setImageIndex(e)
      })
    })
    function setImageIndex (e) {
      const index = e.target.dataset.imageId
      store.commit('configurator/SET_EXAMPLE_IMAGE_INDEX', index)
    }
    function openModal (i) {
      console.log(i)
    }
    return {
      images,
      loading,
      openModal
    }
  }
}
</script>
<style scoped>
.carousel-item {
  transition: transform 0.2s ease-in-out;
}
</style>
