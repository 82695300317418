<template>
<div v-if="nav" class="bg-dark step-navigation">
  <div class="container">
    <ul class="nav nav-tabs">
      <li v-for="(step, index) in nav" :key="index" class="nav-item">
        <a class="nav-link"
        :class="{ active: step.id === currentStep.id }"
        aria-current="page"
        href="#"
        @click.prevent="changeCurrentStep(step.id)">
          {{ step.name }}
          <span class="badge" :class="{ 'bg-light text-dark': step.id !== currentStep.id, 'bg-primary': step.id === currentStep.id }">{{ index + 1 }}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  name: 'StepNavigation',
  setup () {
    const store = useStore()
    const nav = computed(() => {
      return store.getters['configurator/navigation']
    })
    const currentStep = computed(() => {
      return store.getters['configurator/currentStep']
    })
    function changeCurrentStep (id) {
      store.dispatch('configurator/setCurrentStep', id)
    }
    return {
      nav,
      currentStep,
      changeCurrentStep
    }
  }
}
</script>
<style scoped>
.step-navigation {
  font-size: .8125rem;
}
.step-navigation .nav-tabs .nav-link {
  color: #fff;
}
.step-navigation .nav-tabs .nav-link.active {
  color: #000;
}
.nav-tabs .nav-link.active {
  margin-top: -5px;
  padding-top: 13px;
}
.nav-link {
  padding: 0.5rem 0.8rem;
}
.nav-link:hover {
  background: #fff;
}
.step-navigation .nav-tabs .nav-link:hover {
  color: #000;
}
</style>
